import { SiteFile } from 'api/site';

interface FormatDate {
  date: string;
  countryCode?: string;
  dateStyle?: 'short' | 'medium' | 'long';
  timeStyle?: 'short' | 'medium' | 'long';
}

export const formatDate = (data: FormatDate) => {
  const newDate = new Date(data.date);
  const options = {
    dateStyle: data.dateStyle ?? 'medium',
    timeStyle: data.timeStyle ?? 'short',
  };
  const formatted = new Intl.DateTimeFormat(data.countryCode ?? 'en-US', options as object).format(
    newDate
  );
  return formatted;
};

export const formatFileModifiedDate = (locale: string, timestamp: SiteFile['mtime']) => {
  const date = new Date(timestamp! * 1000);

  const datePart = date.toLocaleString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const timePart = date.toLocaleString(locale, { hour: '2-digit', minute: '2-digit' });

  return `${datePart} \u00b7 ${timePart}`;
};
